import { Label } from "@/components/ui/label";
import { QueryKeys, downloadPriceSheet, fetchModelList } from "@/lib/queries";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "@/context/context";
import { useMutation, useQuery } from "react-query";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Dealer } from "@/interfaces/dealer.interface";

const DownloadPriceSheet = () => {
  const { state } = useContext(AppContext);
  
  // Use the correct type for the query result
  const { data: response, isLoading } = useQuery(QueryKeys.MODELS, () => fetchModelList(dealerUser?.dealer_info.type || "all"), {
    refetchOnWindowFocus: false,
  });

  // Extract model list from the response
  const models = response?.model_list || [];

  const [selectedMake, setSelectedMake] = useState<string>("");
  const [isResponse, setIsResponse] = useState(false);

  // Typecast state.user to Dealer if it exists
  const dealerUser = state.user && (state.user as Dealer);

  // Log dealerUser level to the console for debugging
  useEffect(() => {
    if (dealerUser) {
      console.log("Dealer Level:", dealerUser.dealer_info.type);
    }
  }, [dealerUser]);

  const mutation = useMutation(({ level, make }: { level: string, make: string }) => downloadPriceSheet(level, make), {
    onSuccess(data) {
      if (data.status === "success") {
        setIsResponse(true);
      } else {
        setIsResponse(false);
      }
    },
  });

  const onMakeChange = (make: string) => {
    setSelectedMake(make);

    if (dealerUser && dealerUser.dealer_info.type) {
      mutation.mutate({ level: dealerUser.dealer_info.type, make });
    } else {
      console.error("Dealer level is not available.");
    }
  };

  const handleDownloadAll = () => {
    if (dealerUser && dealerUser.dealer_info.type) {
      const url = `https://retail.goldenleafautomotive.com/Excel/${dealerUser.dealer_info.type}/PriceSheet%20-%20all.xlsx`;
      const iframe = document.getElementById('downloadFrame') as HTMLIFrameElement;
      iframe.src = url; // Set the iframe source to trigger the download
    } else {
      console.error("Dealer level is not available.");
    }
  };

  const handleDownloadSelectedMake = () => {
    if (dealerUser && dealerUser.dealer_info.type) {
      const url = `https://retail.goldenleafautomotive.com/Excel/${dealerUser.dealer_info.type}/PriceSheet - ${selectedMake}.xlsx`;
      const iframe = document.getElementById('downloadFrame') as HTMLIFrameElement;
      iframe.src = url; // Set the iframe source to trigger the download
    } else {
      console.error("Dealer level is not available.");
    }
  };

  return (
    <div className="my-10">
      <div className="mt-5">
        <h2 className="font-bold text-4xl text-[#3D3D3D]">Download Price Sheet</h2>
      </div>
      <div className="mt-5 max-w-md space-y-2">
        <Label>Select Model</Label>
        <Select
          value={selectedMake}
          onValueChange={(value: string) => onMakeChange(value)}
          disabled={isLoading || mutation.isLoading}
        >
          <SelectTrigger className="border-black">
            <SelectValue placeholder="Model" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {models.length > 0 &&
                models.map((model, index) => (
                  <SelectItem key={index} value={model} className="capitalize">
                    {model}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center mt-5 gap-3">
        <Button 
          onClick={handleDownloadAll} 
          disabled={isLoading || mutation.isLoading || !dealerUser?.dealer_info.type}
        >
          Download All Price Sheets
        </Button>
        {isResponse && (
          <Button 
            variant={"secondary"} 
            disabled={isLoading || mutation.isLoading || !dealerUser?.dealer_info.type}
            onClick={handleDownloadSelectedMake}
          >
            Download Price Sheet for {selectedMake}
          </Button>
        )}
      </div>
      {/* Hidden iframe for downloading files */}
      <iframe id="downloadFrame" style={{ display: "none" }}></iframe>
    </div>
  );
};

export default DownloadPriceSheet;