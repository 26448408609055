import { CustomInput } from "@/components/ui/customInput";
import { Mail } from "lucide-react";
import { useForm } from "react-hook-form";
import { SignInSchema, signInSchema } from "./types/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { dealerLogin, signIn } from "@/lib/queries";
import { useContext, useEffect } from "react";
import { AppContext } from "@/context/context";
import { toast } from 'react-toastify';

const SignInPage = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(AppContext);

  const form = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
      remmemberMe: false,
    },
  });

  const dealerMutation = useMutation(dealerLogin, {
    onSuccess: (data) => {
      if (typeof data.dealer === "string") {
        toast.error(data.dealer);
        return;
      } else {
        dispatch({ type: "SET_USER", payload: data.dealer });
        dispatch({ type: "SET_USER_INFO", payload: data.dealerInfo });
        navigate("/");
      }
    },
    onError: (error) => {
      console.log("Dealer login error:", error);
    },
  });

  const mutation = useMutation(signIn, {
    onSuccess: (data) => {
      if (!Array.isArray(data) && Object.keys(data).length > 0) {
        if (data.userInfo && data.billing) {
          dispatch({ type: "SET_USER", payload: data.user });
          dispatch({ type: "SET_USER_INFO", payload: data.userInfo });
          dispatch({ type: "SET_BILLING_INFO", payload: data.billing });
          navigate("/");
        } else if (!data.userInfo && !data.billing) {
          dispatch({ type: "SET_USER", payload: data.user });
          navigate("/customer-info");
        }
      } else {
        // If signIn returns empty or invalid data, trigger dealerLogin
        dealerMutation.mutate({
          account: form.getValues("email"),
          password: form.getValues("password"),
        });
      }
    },
    onError: (error) => {
      console.log("Sign-in error:", error);
      // Trigger dealerLogin on sign-in error
      dealerMutation.mutate({
        account: form.getValues("email"),
        password: form.getValues("password"),
      });
    },
  });

  const onSubmit = (data: SignInSchema) => {
    console.log("Form submitted with data:", data);
    mutation.mutate({ email: data.email, password: data.password });
  };

  useEffect(() => {
    if (state.user) {
      navigate("/profile");
    }
  }, [state.user, navigate]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      form.handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="container mx-auto flex flex-col items-center gap-5 p-5 mt-44">
      <h1 className="font-black text-3xl text-center">Welcome to Golden Leaf Automotive!</h1>
      <p className="font-light text-center max-w-5xl mx-auto">
        Your premier destination for vintage car enthusiasts! Explore our extensive collection of rare and authentic
        vintage car parts and bodies. Whether you are restoring a classic beauty or adding unique touches to your
        project, we are here to provide you with top-quality components to bring your vision to life. Sign in to access
        our exclusive offerings and embark on your journey into automotive history with Golden Leaf Automotive.
      </p>

      <div className="flex items-center gap-2 flex-col my-2 w-full md:w-1/2 lg:w-2/6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 w-full">
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="dealer ID / yourname@email.com"
                      startIcon={Mail}
                      className="h-16 border-[#BEBEBE] mt-8"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="yourpassword"
                      type="password"
                      className="border-[#BEBEBE] h-16 mt-5"
                      {...field}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <div className="flex justify-between items-center m-0">
              <FormField
                name="remmemberMe"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0 ">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} className="border-[#7E7E7E]" />
                    </FormControl>
                    <div className="space-y-1 leading-none text-[#7E7E7E]">
                      <FormLabel>Remember me</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <Button
                variant={"link"}
                className="italic text-[#7E7E7E] underline decoration-[#7E7E7E]"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/forgetpassword");
                }}
              >
                Forgot password?
              </Button>
            </div>
            <div className="pt-10 space-x-2 flex justify-between items-center">
              <Button
                type="submit"
                className="bg-[#FFE512] w-[48%] h-16 hover:bg-[#FFE512] text-black text-[clamp(0.875rem,3cqw,1.125rem)] rounded-lg"
              >
                Sign In
              </Button>
              <Button
                variant="outline"
                className="w-[48%] h-16 text-[clamp(0.875rem,3cqw,1.125rem)] px-2 rounded-lg"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/register");
                }}
              >
                Register and join now
              </Button>
            </div>

            <div className="pt-10 space-x-2 flex justify-between items-center">
              <p className="font-light text-center max-w-5xl mx-auto ">
                If you require or have forgotten your dealer login information, please contact us at 905-850-3433
              </p>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default SignInPage;
