import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { QueryKeys, getOrderReceipt, getOneOrder, getItemsInCart } from "@/lib/queries";
import { Button } from "@/components/ui/button";
import OrderTable from "./components/OrderTable";
import OrderSummary from "./components/OrderSummary";
import { AppContext } from "@/context/context";
import { OneOrderAddress, UserInfo } from "@/interfaces/user.interface"; // Import UserInfo
import { Dealer } from "@/interfaces/dealer.interface";
import { OneOrderProduct } from "@/interfaces/product.interface"; // Import Product

const OrderReceiptPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  
  // Read URL parameters
  const userIdFromUrl = searchParams.get("id");
  const orderSonoFromUrl = searchParams.get("so");

  // Determine the user ID and order number to use
  const userId = userIdFromUrl || state.user?.id;
  const orderSono = orderSonoFromUrl || state.orderSono;

  // Fetch order receipt data
  const { data: receiptData } = useQuery(
    QueryKeys.ORDER_RECEIPT,
    () => getOrderReceipt(Number(userId)),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    }
  );

  // Fetch the specific order data using orderSono and user ID
  const { data: orderData } = useQuery(
    [QueryKeys.ONE_ORDER, orderSono, userId],
    () => getOneOrder(Number(orderSono), Number(userId)),
    {
      enabled: !!orderSono && !!userId,
    }
  );

  // Fetch cart items for the header cart icon
  const dealerUser = state.user as Dealer;
  const { refetch: refetchCart } = useQuery(
    QueryKeys.CART,
    () => getItemsInCart({
      user: state.user?.id as number,
      type: dealerUser && dealerUser.account ? 2 : 1
    }),
    {
      enabled: !!state.user,
      refetchOnWindowFocus: false,
    }
  );

  // Placeholder for dynamically fetched data (mockup)
  const userInfo: UserInfo = receiptData?.userInfo || ({} as UserInfo);
  const carts: OneOrderProduct[] = orderData?.oneOrder || [];
  const subtotal = orderData?.somast.subtotal || 0;
  const taxTotal = orderData?.somast.tax || 0;
  const totalShipping = orderData?.somast.shipping || 0;
  const total = subtotal + taxTotal + totalShipping;

  // Determine which address to use
  // const shippingAddress: OneOrderAddress =
  //   orderData?.somast.address === 0
  //     ? ({} as OneOrderAddress) // Fallback address if `address` is 0
  //     : orderData?.address || ({} as OneOrderAddress);
  // Shipping address logic
  const shippingAddress: OneOrderAddress = orderData?.address && orderData.somast.address !== 0 
  ? orderData.address 
  : {
      forename: userInfo.m_forename,
      surname: userInfo.m_surname,
      address: userInfo.m_address,
      city: userInfo.m_city,
      state: userInfo.m_state,
      zipcode: userInfo.m_zipcode,
      country: userInfo.m_country,
      tel: userInfo.m_tel,
    };

  useEffect(() => {
    if (orderData) {
      refetchCart();
    }
  }, [orderData, refetchCart]);

  const handlePrintOrder = () => {
    const printUrl = `https://retail.goldenleafautomotive.com/PDF/salesOrder/${orderSono}.pdf`;
    window.open(printUrl, '_blank');
  };

  return (
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Order Receipt</h1>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 space-y-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h4 className="text-lg font-semibold text-white ml-4">Shipping to</h4>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 mx-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">
                  {shippingAddress.forename} {shippingAddress.surname}
                </p>
                <p>
                  {shippingAddress.address}, <br /> {shippingAddress.city}, {shippingAddress.state},{" "}
                  {shippingAddress.zipcode} <br />
                  {shippingAddress.country}
                </p>
                <p>{shippingAddress.tel}</p>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h2 className="text-lg font-semibold text-white ml-4">Billing to</h2>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 mx-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">
                  {userInfo.m_forename} {userInfo.m_surname}
                </p>
                <p>
                  {userInfo.m_address}, <br /> {userInfo.m_city}, {userInfo.m_state}, {userInfo.m_zipcode}{" "}
                  <br />
                  {userInfo.m_country}
                </p>
                <p>{userInfo.m_tel}</p>
              </div>
            </div>
          </div>
          <OrderTable items={carts} /> {/* Pass items as carts */}
        </div>
        <div className="col-span-1">
          <OrderSummary
            subtotal={subtotal}
            shipping={totalShipping}
            tax={taxTotal}
            total={total}
          />
        </div>
      </div>

      <div className="mt-20 auto-mx flex justify-center gap-3 grid grid-cols-1">
        <div className="text-center text-2xl font-bold">
          <p>
            Thank you for shopping with Golden Leaf Automotive! <br /> Your order number is: {orderSono}
          </p>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 mt-6">
          <Button
            className="bg-[#184178] h-12 hover:bg-[#184178] rounded-2xl text-md text-white font-bold mx-10 my-2 md:my-0"
            type="button"
            onClick={() => navigate("/profile?tab=Order History")}
          >
            Order History
          </Button>
          <Button
            className="bg-[#184178] h-12 hover:bg-[#184178] rounded-2xl text-md text-white font-bold mx-10 my-2 md:my-0"
            type="button"
            onClick={handlePrintOrder}
          >
            Print Order
          </Button>
          <Button
            className="bg-white hover:bg-gray-100 border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black md:w-4/5 shadow-md mx-10 my-2 md:my-0"
            type="button"
            onClick={() => navigate("/")}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderReceiptPage;
