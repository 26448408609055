import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { QueryKeys, getClassicModelDetail } from "@/lib/queries";
import { useQuery } from "react-query";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { IMAGE_URL } from "@/config/appConfig";
import { useNavigate } from "react-router";
import { ClassicBodyModelItem } from "@/interfaces/product.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { useContext } from "react";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";

interface ModelDetailPageProps {
  modelCode: string;
  onClose: () => void;
}

const ModelDetailPage = ({ modelCode, onClose }: ModelDetailPageProps) => {
  const { data } = useQuery(QueryKeys.CLASSIC_MODEL_DETAIL, () => getClassicModelDetail(modelCode as string), {
    refetchOnWindowFocus: false,
    enabled: !!modelCode,
  });

  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const dealerUser = state.user as Dealer;

  /**
   * Converts a string with line breaks into an array of strings.
   * @param str - The input string with line breaks.
   * @returns An array of strings split at the line breaks.
   */
  function convertLineBreaksToArray(str: string | null | undefined): string[] {
    if (!str) {
      return [];
    }
    return str.split("\r\n");
  }
  
  const fetchPrice = (product: ClassicBodyModelItem) => {
    const isDealer = dealerUser && dealerUser.account;

    const priceLevel = getPriceAgainstLevel((dealerUser && (dealerUser.level as number)) || 0, product);
    return isDealer ? priceLevel : product.pricel;
  };

  useEffect(() => {
    // Log the onClose function to satisfy ESLint and TypeScript
    console.log(onClose);
  }, [onClose]);

  return (
    <section className="relative flex">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mx-auto max-md:px-2">
          <div className="img flex items-center">
            <div className="h-full flex flex-col justify-start items-center w-full">
              <h4 className="text-[#184178] font-black text-2xl mt-10 mb-4">{data && data.singleItem.item}</h4>

              <Carousel className="w-full max-w-xs">
                <CarouselContent>
                  {data &&
                    data.img_path &&
                    data.img_path.length > 0 &&
                    data.img_path.map((image, index) => (
                      <CarouselItem key={index}>
                        <img
                          src={IMAGE_URL + image.path}
                          alt="product image"
                          className="object-contain object-center mx-auto h-[300px]"
                        />
                      </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </div>
          </div>
          <div className="data w-full lg:pr-8 pr-0 xl:justify-start justify-center flex items-center max-lg:pb-10 xl:my-2 lg:my-5 my-0">
            <div className="data w-full max-w-xl">
              <div className="mt-4 bg-[#FAFAFA] rounded-lg p-5">
                <h1 className="font-black text-2xl mt-6">{data && data.singleItem.descrip}</h1>
                <p className="text-md mt-9 font-medium ml-36">
                  Year fit: {data && data.singleItem.year_from + "-" + data.singleItem.year_end}
                </p>
                <p className="text-md mt-9 font-medium ml-36">Make: {data && data.singleItem.make}</p>
                <p className="text-md mt-9 font-medium ml-36">Model: {data && data.singleItem.model}</p>
                <p
                  className="text-[#2F50FF] font-semibold underline mt-2 text-end cursor-pointer"
                  onClick={() => navigate(`/shipping-inquiry/${modelCode}?type=model`)}
                >
                  Inquire
                </p>
              </div>
              <h3 className="text-3xl text-center ml-14 font-black p-5 text-[#3D3D3D]">
                CAD {data && fetchPrice(data.singleItem).toFixed(2)}
              </h3>
              <div className="flex items-center justify-center mt-10">
                <Button
                  className="bg-[#184178] rounded-2xl text-white font-semibold w-[220px] hover:bg-[#184178]"
                  onClick={() => navigate(`/shipping-inquiry/${modelCode}?type=model`)}
                >
                  Email to order
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="ml-10">
          <h5 className="font-black my-3 text-lg">Includes</h5>
          <ul className="space-y-4">
            {data &&
              data.info &&
              convertLineBreaksToArray(data.info[0].includes).map((item, index) => (
                <li key={index} className="capitalize">
                  {item}
                </li>
              ))}
          </ul>
          <h5 className="font-black my-3 text-lg mt-6">Shipping</h5>
          <ul className="space-y-4 mb-6 ">
            {data &&
              data.info &&
              convertLineBreaksToArray(data.info[0].shipping).map((item, index) => (
                <li key={index} className="capitalize">
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ModelDetailPage;
