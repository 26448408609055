const BAMBORA_API_BASE_URL = 'https://api.na.bambora.com/v1';
//const TOKENIZATION_URL = 'https://web.na.bambora.com/scripts/tokenization/tokens';
const MERCHANT_ID = '383612645';
const PASSCODE = 'fdA3992b57D047Af8980b5013aEe0A19'; // Replace with your actual passcode

// Define interface for Card Data
interface CardData {
  number: string;
  expiry_month: string;
  expiry_year: string;
  cvd: string;
}

// Define interface for Payment Data
interface PaymentData {
  order_number: string;
  amount: number;
  payment_method: string;
  customer_ip: string;
  term_url: string;
  billing: {
    name: string;
    address_line1: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    phone_number: string;
  }
  shipping:{
    name: string;
    address_line1: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    phone_number: string;
  }
  token: {
    code: string;
    name: string;
    complete: boolean;
    '3d_secure': {
      browser: {
        accept_header: string;
        java_enabled: boolean;
        language: string;
        color_depth: string;
        screen_height: number;
        screen_width: number;
        time_zone: number;
        user_agent: string;
        javascript_enabled: boolean;
      };
      enabled: boolean;
      version: number;
      auth_required: boolean;
    };
  };
}

// Get Token from Bambora
export const getToken = async (cardData: CardData) => {
  try {
    const response = await fetch('https://retail.goldenleafautomotive.com/api/getTokenBambora', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cardData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Tokenization failed:', errorData);
      throw new Error(`Failed to tokenize card: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error tokenizing card:', error);
    throw error;
  }
};

// Initiate Payment with Bambora
export const initiatePayment = async (paymentData: PaymentData) => {
  try {
    const authString = `${MERCHANT_ID}:${PASSCODE}`;
    const encodedAuthString = btoa(authString); // base64 encoding

    const response = await fetch(`${BAMBORA_API_BASE_URL}/payments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Passcode ${encodedAuthString}`,
      },
      body: JSON.stringify(paymentData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Payment initiation failed:', errorData);
      throw new Error(`Failed to initiate payment: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

// Continue Payment (3D Secure)
export const continuePayment = async (sessionData: string, cres: string) => {
  try {
    const response = await fetch(`${BAMBORA_API_BASE_URL}/payments/${sessionData}/continue`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Passcode ${PASSCODE}`,
      },
      body: JSON.stringify({
        payment_method: 'credit_card',
        card_response: {
          cres,
        },
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Continue payment failed:', errorData);
      throw new Error(`Failed to continue payment: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error continuing payment:', error);
    throw error;
  }
};
