import { ClassicBodyModelItem, Product } from "@/interfaces/product.interface";

/**
 * Returns the price against the given level for a product.
 * @param level - The level for which to retrieve the price.
 * @param product - The product or classic body model item.
 * @returns The price corresponding to the given level.
 */

export const getPriceAgainstLevel = (level: number, product: Product | ClassicBodyModelItem): number => {
  if (level === 1) {
    return product.price;
  } else if (level === 2) {
    return product.price2;
  } else if (level === 3) {
    return product.price3;
  } else if (level === 4) {
    return product.price4;
  } else if (level === 5) {
    return product.price5;
  } else if (level === 6) {
    return product.price6;
  }
  return product.pricel;
};
