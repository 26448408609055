import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import SectionCard from "@/components/sectionCard";
import SectionTitle from "@/components/sectionTitle";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { IMAGE_URL } from "@/config/appConfig";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Product } from "@/interfaces/product.interface";
import { QueryKeys, fetchHotSaleProducts, specialsProducts } from "@/lib/queries"; // Import specialsProducts query
import { truncate } from "@/lib/utils";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

const HotSaleProducts = () => {
  const queryClient = useQueryClient();
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const [specialPrices, setSpecialPrices] = useState<{ [key: string]: { salePrice: number; orgPrice: number } }>({}); // Track special prices

  const { state } = useContext(AppContext);

  const dealerUser = state.user && (state.user as Dealer);

  // Fetch Hot Sale Products
  const { data: products } = useQuery([QueryKeys.HOT_SALE_PRODUCTS], fetchHotSaleProducts, {
    refetchOnWindowFocus: false,
  });

  // Fetch special prices for non-dealers
  useQuery(QueryKeys.SPECIAL, specialsProducts, {
    enabled: !dealerUser, // Fetch only for non-dealers
    onSuccess: (data) => {
      const specialsMap = data?.item.reduce((acc, product) => {
        acc[product.item] = {
          salePrice: product.sale_price || 0, // Fallback to 0 to ensure it's a number
          orgPrice: product.org_price || 0,   // Fallback to 0 to ensure it's a number
        };
        return acc;
      }, {} as { [key: string]: { salePrice: number; orgPrice: number } });
      setSpecialPrices(specialsMap);
    },
  });

  // Fetch price based on dealer/non-dealer logic
  const fetchPrice = (product: Product) => {
    if (dealerUser) {
      const dealerType = Number(dealerUser?.dealer_info?.type) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      const specialPriceData = specialPrices[product.item];
      if (specialPriceData?.salePrice) {
        return specialPriceData.orgPrice; // Return original price if special price exists
      } else if (product.onsale) {
        return product.pricel; // Regular price for on-sale items (the discount will be displayed in the card)
      } else {
        return product.pricel; // Regular price as fallback
      }
    }
  };

  return (
    <div className="my-8 px-4 md:px-6">
      <SectionTitle
        title="Most Popular"
        subTitle="Hot"
        category="Products"
        onRefresh={() => queryClient.invalidateQueries(QueryKeys.HOT_SALE_PRODUCTS)}
      />
      <Carousel className="my-5" opts={{ align: "start" }}>
        <CarouselContent>
          {products &&
            products.length > 0 &&
            products.map((product, index) => {
              const specialPriceData = specialPrices[product.item];
              const specialPrice = specialPriceData?.salePrice ? `CAD ${specialPriceData.salePrice.toFixed(2)}` : undefined;
              const promoPrice = product.onsale ? `CAD ${(product.pricel * 0.9).toFixed(2)}` : undefined;

              return (
                <CarouselItem key={index} className="md:basis-1/3 sm:basis-1/2 basis-full lg:basis-1/4 pt-7 md:pt-0">
                  <SectionCard
                    className="h-[450px]"
                    code={product.item}
                    make={product.make}
                    price={`CAD ${fetchPrice(product).toFixed(2)}`}
                    promoPrice={promoPrice} // Pass promo price
                    specialPrice={specialPrice} // Pass special price
                    year={product.year_from + "-" + product.year_end}
                    title={truncate(product.descrip, 20)}
                    onClick={() => {
                      setProductId(product.item);
                      setShowProductDialog(true);
                    }}
                    image={IMAGE_URL + product.img_path}
                  />
                </CarouselItem>
              );
            })}
        </CarouselContent>
      </Carousel>
      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default HotSaleProducts;
